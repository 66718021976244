import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Container, Image } from "react-bootstrap";
import "./App.css";
import image from "./assets/photo.jpg";
import NormalAccordion from "./components/Accordion";
import NomalCard from "./components/Card";
import TagsList from "./components/TagsList";
import Text from "./components/Text";

function App() {
  let header = require("./data/header.json");
  let sections = require("./data/data.json");

  return (
    <>
      <div style={{ background: "#198754" }}>
        <Container
          style={{ paddingTop: 50, paddingBottom: 30, maxWidth: 1024 }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1, textAlign: "left" }}>
              <h2>Full Stack Web and Mobile Analyst Developer</h2>
              <h3 style={{ marginBottom: 25 }}>Sylvain Vandevelde</h3>
              {header.map((h, index) => (
                <div
                  key={index}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    height: 30,
                  }}
                >
                  <div style={{ width: 20, textAlign: "center" }}>
                    <i className={`fa-solid fa-${h.icon}`} />
                  </div>
                  <div style={{ marginLeft: 10 }}>{h.content}</div>
                </div>
              ))}
              <a href="./CV - Sylvain Vandevelde.pdf" target="_blank">
                <Button
                  size="sm"
                  style={{ marginTop: 25, marginLeft: -4 }}
                  variant="dark"
                >
                  <i
                    className="fa-solid fa-file-pdf"
                    style={{ marginRight: 12 }}
                  />
                  Download PDF Version
                </Button>
              </a>
            </div>
            <Image
              src={image}
              style={{ height: 200, marginTop: 10 }}
              roundedCircle
            />
          </div>
        </Container>
      </div>
      <Container style={{ paddingTop: 30, paddingBottom: 30, maxWidth: 1024 }}>
        {sections.map((s, index) => (
          <div key={index}>
            {s.title && (
              <h1 style={{ marginTop: 50, marginBottom: 20, marginLeft: 140 }}>
                {s.title}
              </h1>
            )}
            {s.type === "text" && <Text content={s.content} />}
            {s.type === "accordion" && <NormalAccordion content={s.content} />}
            {s.type === "card" && <NomalCard content={s.content} />}
            {s.type === "tagsList" && <TagsList content={s.content} />}
          </div>
        ))}
      </Container>
    </>
  );
}

export default App;
