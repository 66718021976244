import { Badge, Card } from "react-bootstrap";

const NomalCard = ({ content }) => {
  return content.map((c, index) => (
    <Card key={index}>
      <Card.Body>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h5>
            <Badge
              style={{
                alignSelf: "start",
                width: 111,
              }}
            >
              {c.tag}
            </Badge>
          </h5>
          <div style={{ marginLeft: 10 }}>
            <h4 style={{ marginBottom: 0 }}>{c.title}</h4>
            {c.subtitle}
          </div>
        </div>
      </Card.Body>
    </Card>
  ));
};

export default NomalCard;
