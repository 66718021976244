import { Card } from "react-bootstrap";

const Text = ({ content }) => {
  return (
    <Card>
      <Card.Body>
        <Card.Text
          style={{
            fontStyle: "italic",
            marginLeft: 10,
            marginRight: 10,
            textAlign: "justify",
            fontSize: 18,
          }}
        >
          {content.map((c) => (
            <p>{c}</p>
          ))}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Text;
