import { Accordion, Badge } from "react-bootstrap";

const NormalAccordion = ({ content }) => {
  return (
    <Accordion>
      {content.map((c, index) => (
        <Accordion.Item eventKey={index} key={index}>
          <Accordion.Header>
            <h5>
              <Badge
                style={{
                  alignSelf: "start",
                  position: "relative",
                  top: -9,
                  width: 111,
                }}
              >
                {c.tag}
              </Badge>
            </h5>
            <div style={{ marginLeft: 10 }}>
              <h4>{c.title}</h4>
              {c.subtitle}
              {c.subtitle2 && ` - ${c.subtitle2}`}
            </div>
          </Accordion.Header>
          <Accordion.Body style={{ paddingTop: 25, marginBottom: 15 }}>
            <ul style={{ marginBottom: 0, marginLeft: 105 }}>
              {c.listItems.map((r, index) => (
                <li key={index}>{r}</li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default NormalAccordion;
