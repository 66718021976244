import { Badge, Card, Col, Container } from "react-bootstrap";

const TagsList = ({ content }) => {
  return content.map((c, index) => (
    <Card key={index}>
      <Card.Body style={{ paddingTop: 0 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h4>
            <Badge style={{ alignSelf: "start", width: 111, marginTop: 16 }}>
              {c.tag}
            </Badge>
          </h4>
          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {c.listItems.map((i, index) => (
              <Col
                style={{
                  paddingLeft: 10,
                  textAlign: "center",
                  marginTop: 16,
                }}
                key={index}
                xs={4}
                md={3}
              >
                <h4>
                  <Badge bg="success" style={{ width: "100%" }}>
                    {i.title}
                  </Badge>
                </h4>
                <div style={{ marginTop: -5 }}>{i.subtitle}</div>
              </Col>
            ))}
          </Container>
        </div>
      </Card.Body>
    </Card>
  ));
};

export default TagsList;
